<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>供销企业管理</el-breadcrumb-item>
        <el-breadcrumb-item>供销企业列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt0">
      <div class="pt20" style="text-align: left">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="供销企业名称"
              v-model="page.supply_name"
              clearable
              @clear="onSubmit(page.warehouse_name)"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              placeholder="联系人"
              v-model="page.concat"
              clearable
              @clear="onSubmit(page.warehouse_name)"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" size="medium"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-upload
              class="upload"
              :multiple="false"
              action="#"
              ref="upload"
              accept=".xlsx"
              :limit="1"
              :show-file-list="false"
              :before-upload="beforeUpload"
              style="float: right"
            >
              <el-button type="success" size="medium" @click="batchAdd">
                批量导入
              </el-button>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button size="medium" type="warning" @click="downloadInfo(1)">
              下载模板
            </el-button>
          </el-form-item>
          <el-form-item v-if="showAdd">
            <el-button
              type="success"
              size="medium"
              @click="dialogVisible = true"
            >
              新增供应商
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search mt20">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="供销企业名称"
                v-model="page.supply_name"
                clearable
                class="input-with-select"
                @clear="onSubmit(page.warehouse_name)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                      placeholder="联系人"
                      v-model="page.concat"
                      clearable
                      class="input-with-select"
                      @clear="onSubmit(page.warehouse_name)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="tl mr10">
            <el-form-item size="medium">
              <el-button
                type="primary"
                @click="onSubmit"
                class="general_bgc general_border cfff"
                >查询</el-button
              >
            </el-form-item>
          </el-col>
          <el-col :span="2" class="tl mr10">
            <el-form-item>
              <el-upload
                      class="upload"
                      :multiple="false"
                      action="#"
                      ref="upload"
                      accept=".xlsx"
                      :limit="1"
                      :show-file-list="false"
                      :before-upload="beforeUpload"
                      style="float: right">
                <el-button type="success" size="medium" @click="batchAdd"
                >批量导入</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item>
              <el-button size="medium" type="warning" @click="downloadInfo(1)">下载模板</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row> -->
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%" id="out-table">
          <el-table-column
            fixed
            align="center"
            width="100"
            ref="multipleTable"
            type="index"
            label="序号"
          >
          </el-table-column>

          <el-table-column
            prop="supply_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="供销单位名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="contact"
            min-width="120"
            :show-overflow-tooltip="true"
            label="联系人"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="contact_info"
            min-width="120"
            :show-overflow-tooltip="true"
            label="联系人电话"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="credit_url"
            min-width="80"
            :show-overflow-tooltip="true"
            label="营业执照"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="handlePictureCardPreview(scope.row.credit_url)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="record_url"
            min-width="80"
            :show-overflow-tooltip="true"
            label="备案证"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="handlePictureCardPreview(scope.row.record_url)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="license_url"
            min-width="80"
            :show-overflow-tooltip="true"
            label="许可证"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="handlePictureCardPreview(scope.row.license_url)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="credit_code"
            min-width="160"
            :show-overflow-tooltip="true"
            label="统一社会信用代码"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="180"
            fixed="right"
            align="center"
            prop="company_supply_id"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib red"
                size="mini"
                @click="deleteInfo(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog :visible.sync="showViewer">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </el-main>
    <el-dialog title="新增" :visible.sync="dialogVisible" width="40%">
      <div>
        <el-form ref="form" :model="form" label-width="140px" :rules="rules">
          <el-form-item label="供销单位名称" prop="supplyName">
            <div style="width: 70%">
              <el-autocomplete
                placeholder="供销单位名称"
                v-model="form.supplyName"
                :fetch-suggestions="querySearchAsync"
                clearable
                style="width: 60%; float: left"
                @select="handleSelect"
              >
                <template slot-scope="{ item }">
                  <span class="name">{{ item.company_name }}</span>
                  <span
                    class="addr"
                    style="font-size: 12px; color: silver; float: right"
                    >{{ item.credit_code }}</span
                  >
                </template>
              </el-autocomplete>
            </div>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="creditCode">
            <div style="width: 70%">
              <el-input v-model="form.creditCode"></el-input>
            </div>
          </el-form-item>
           <el-form-item size="medium" label="经营地址" prop="address">
            <div class="df aic">
              <div v-if="chooseMap" style="margin-right: 10px">
                {{ chooseMap.address }}
              </div>
              <el-popover
                placement="right"
                width="800"
                trigger="click"
                v-model="showPopover"
              >
                <div class="df" style="width: 100%; flex-direction: column">
                  <div style="flex: 0 0 60px; padding: 0 20px" class="df aic">
                    <el-autocomplete
                      v-model.trim="form.address"
                      :fetch-suggestions="querySearch"
                      placeholder="请输入详细地址"
                      style="width: 100%"
                      :trigger-on-focus="false"
                      @select="handleMapSelect"
                    />
                  </div>
                  <div style="flex: 0 0 380px; position: relative">
                    <div
                      style="
                        position: absolute;
                        left: 385px;
                        top: 150px;
                        z-index: 9999;
                      "
                    >
                      <img
                        src="@/assets/image/location.png"
                        alt=""
                        style="width: 30px; height: 30px"
                      />
                    </div>
                    <baidu-map
                      id="allmap"
                      :zoom="zoom"
                      :mapClick="false"
                      @click="getLocation"
                      @ready="mapReady"
                      :scroll-wheel-zoom="true"
                      @dragging="mapMoving"
                      @zoomend="zoomend"
                    >
                    </baidu-map>
                  </div>
                  <div
                    style="flex: 0 0 80px; padding: 0 20px"
                    v-if="surroundingPois.length"
                  >
                    <el-tag
                      style="margin: 5px; cursor: pointer"
                      v-for="item in surroundingPois"
                      :key="item.uid"
                      @click="gotoPoint(item)"
                      >{{ item.title }}</el-tag
                    >
                  </div>
                  <div
                    style="
                      flex: 0 0 60px;
                      justify-content: flex-end;
                      padding: 0 20px;
                    "
                    class="df aic"
                  >
                    <el-button type="primary" @click="chooseGPS"
                      >选择当前位置</el-button
                    >
                  </div>
                </div>
                <el-button slot="reference">选择定位</el-button>
              </el-popover>
            </div>
          </el-form-item>
          <el-form-item label="企业联系人" prop="contact">
            <div style="width: 70%">
              <el-input v-model="form.contact"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="联系人联系方式" prop="contactInfo">
            <div style="width: 70%">
              <el-input v-model="form.contactInfo"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="营业执照" prop="creditUrl">
            <div class="df" style="width: 70%">
              <el-upload
                class="avatar-uploader"
                action="#"
                :http-request="
                  (params) => {
                    addImg(params, 'creditUrl');
                  }
                "
                :show-file-list="false"
                :limit="1"
              >
                <img
                  v-if="form.creditUrl"
                  :src="form.creditUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="备案证">
            <div class="df" style="width: 70%">
              <el-upload
                class="avatar-uploader"
                action="#"
                :http-request="
                  (params) => {
                    addImg(params, 'recordUrl');
                  }
                "
                :show-file-list="false"
                :limit="1"
              >
                <img
                  v-if="form.recordUrl"
                  :src="form.recordUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="食品经营许可证">
            <div class="df" style="width: 70%">
              <el-upload
                class="avatar-uploader"
                action="#"
                :http-request="
                  (params) => {
                    addImg(params, 'licenseUrl');
                  }
                "
                :show-file-list="false"
                :limit="1"
              >
                <img
                  v-if="form.licenseUrl"
                  :src="form.licenseUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
         
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="df aic" style="justify-content: flex-end">
          <el-button size="mini">取消</el-button>
          <el-button type="primary" size="mini" @click="handleSubmit"
            >确认</el-button
          >
        </div>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import axios from "@/util/api";
import api from "@/util/extra-api";
import { local } from "@/util/util";
import { mapState } from "vuex";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  components: {},
  data() {
    return {
      files: "",
      user: {},
      page: {
        supply_name: "", //供销单位的名称
        concat: "", //联系人
        pager_offset: "0",
        pager_openset: "10",
      },
      timer: "",
      loading: false,
      currentPage: 1,
      total: 0,
      tableData: [],
      activeName: "1",
      company_id: "",
      url: "",
      uploadVis: false,
      showViewer: false,
      dialogImageUrl: "",
      showAdd: false,
      dialogVisible: false,
      form: {
        creditCode: "",
        supplyName: "",
        address: "",
        addressJson: "",
        supplyType: 0,
        creditUrl: "",
        recordUrl: "",
        licenseUrl: "",
        contact: "",
        contactInfo: "",
      },
      address: "",
      coordinate: "",
      mapInfo: {
        lng: 104.074994,
        lat: 30.640429,
      },
      chooseMap: null,
      surroundingPois: [],
      showPopover: false,
      zoom: 20,
      myGeo: null,
      rules: {
        supplyName: [
          { required: true, message: "请输入供应商名称", trigger: "blur" },
        ],
        creditCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ],
        creditUrl: [
          { required: true, message: "请上传供应商营业执照", trigger: "blur" },
        ],
        contact: [
          { required: true, message: "请输入企业联系人", trigger: "blur" },
        ],
        contactInfo: [
          { required: true, message: "请输入联系人联系方式", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "请选择供应商经营地址", trigger: "blur" },
        ],
      },
    };
  },
  filters: {},
  computed: {
    ...mapState({
      carState: (state) => state.carManage.carState,
    }),
  },
  mounted() {
    this.page = Object.assign({}, this.page);
    this.user = local.get("user");
    this.page.company_id = String(local.get("company_id"));
    this.currentPage = this.page.pager_offset / 10 + 1;
    this.init(this.page);
    this.getSupplyQx();
  },
  methods: {
    handleSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          api
            .post(
              "/v1/pc/company/saveSupply?companyId=" + this.page.company_id,
              this.form
            )
            .then((res) => {
              this.$message.success("添加成功");
              this.init(this.page);
              this.dialogVisible = false;
              this.form = {
                creditCode: "",
                supplyName: "",
                address: "",
                addressJson: "",
                supplyType: 0,
                creditUrl: "",
                recordUrl: "",
                licenseUrl: "",
                contact: "",
                contactInfo: "",
              };
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    mapMoving(data) {
      console.log(data.point.lng, data.point.lat);
      let that = this;
      this.mapInfo = data.point;
      this.myGeo.getLocation(data.point, function (result) {
        that.surroundingPois = result.surroundingPois;
      });
    },
    mapReady({ BMap, map }) {
      this.point = new BMap.Point(this.mapInfo.lng, this.mapInfo.lat); // 选择一个经纬度作为中心点
      map.centerAndZoom(this.point, 20); //设置地图中心点和缩放级别
      this.BMap = BMap;
      this.myGeo = new BMap.Geocoder();
      let that = this;
      this.myGeo.getLocation(this.mapInfo, function (result) {
        // console.log(result, 123);
        if (result && result.surroundingPois) {
          that.surroundingPois = result.surroundingPois;
        }
      });
      this.map = map;
      console.log(
        new BMap.Point(this.mapInfo.lng, this.mapInfo.lat),
        this.mapInfo,
        1111111
      );
      // this.map.setCenter(new BMap.Point(this.mapInfo.lng, this.mapInfo.lat));
      this.map.setCenter(this.mapInfo);
      this.mapInfo;
    },
    querySearch(queryString, cb) {
      var that = this;
      var myGeo = new this.BMap.Geocoder();
      myGeo.getPoint(
        queryString,
        function (point) {
          if (point) {
            that.coordinate = point;
          } else {
            that.coordinate = null;
          }
        },
        this.locationCity
      );
      var options = {
        onSearchComplete: function (results) {
          if (local.getStatus() === 0) {
            // 判断状态是否正确

            var s = [];
            for (var i = 0; i < results.getCurrentNumPois(); i++) {
              var x = results.getPoi(i);
              var item = { value: x.address + x.title, point: x.point };
              s.push(item);
              cb(s);
            }
          } else {
            cb();
          }
        },
      };
      var local = new this.BMap.LocalSearch(this.map, options);
      local.search(queryString);
    },
    handleMapSelect(item) {
      var { point } = item;
      let that = this;
      this.coordinate = point;
      this.map.setCenter(new BMap.Point(item.point.lng, item.point.lat));
      this.mapInfo = item.point;
      this.myGeo.getLocation(point, function (result) {
        that.surroundingPois = result.surroundingPois;
        that.chooseMap = {
          address: result.surroundingPois[0].address,
          point: result.surroundingPois[0].point,
        };
      });
    },
    getLocation(e) {
      console.log(e);
      let that = this;
      // this.map.setCenter({ lng: e.point.lng, lat: e.point.lat });
      this.map.setCenter(new BMap.Point(e.point.lng, e.point.lat));
      this.myGeo.getLocation(e.point, function (result) {
        // console.log(result, 123);
        that.surroundingPois = result.surroundingPois;
      });
    },
    zoomend() {
      // console.log("修改", this.mapInfo, this.map);
      if (this.mapInfo && this.map) {
        this.map.setCenter(new BMap.Point(this.mapInfo.lng, this.mapInfo.lat));
        let that = this;
        this.myGeo.getLocation(this.mapInfo, function (result) {
          that.surroundingPois = result.surroundingPois;
        });
      }
    },
    gotoPoint(item) {
      let that = this;
      this.mapInfo = item.point;
      console.log(item, "选择");
      this.map.setCenter(new BMap.Point(item.point.lng, item.point.lat));
      this.myGeo.getLocation(item.point, function (result) {
        that.surroundingPois = result.surroundingPois;
      });
    },
    chooseGPS() {
      let that = this;
      this.myGeo.getLocation(this.mapInfo, function (result) {
        that.surroundingPois = [];
        // console.log(result, "结果");
        let arr = [
          result.addressComponents.province,
          result.addressComponents.city,
          result.addressComponents.district,
          result.addressComponents.street,
          "",
          result.addressComponents.streetNumber,
          result.point.lat + "," + result.point.lng,
        ];
        // console.log(JSON.stringify(arr), "地址");
        // that.chooseMap = {
        //   address: result.address,
        //   point: result.point,
        // };
        that.form.address = result.address;
        that.form.addressJson = JSON.stringify(arr);
        // console.log(result);
      });
      this.showPopover = false;
      this.mapInfo = {
        lng: 104.074994,
        lat: 30.640429,
      };
      this.address = "";
    },
    handleSelect(item) {
      console.log(item, "选择");
      this.form.supplyName = item.company_name;
      this.form.creditCode = item.credit_code;
    },
    querySearchAsync(queryString, cb) {
      if (queryString) {
        axios
          .get("/pc/company/signList/2", {
            company_name: this.form.supplyName,
          })
          .then((response) => {
            let results = response.data.company_list;
            cb(results);
          });
      } else {
        cb([]);
      }
    },
    addImg(params, key) {
      const file = params.file,
        fileType = file.type,
        isImage = fileType.indexOf("image") != -1,
        isLt10M = file.size / 1024 / 1024 < 10;
      // 这里常规检验，看项目需求而定
      if (!isImage) {
        this.$message.error("只能上传图片格式png、jpg、gif!");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("只能上传图片大小小于10M");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", 1117);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("图片上传失败，请重新上传");
        } else {
          this.$set(this.form, key, resp.url);
        }
      });
    },
    getSupplyQx() {
      api
        .get("/v1/pc/company/getSupplyQx", { companyId: this.page.company_id })
        .then((res) => {
          this.showAdd = res.data;
        });
    },
    beforeUpload(file) {
      // console.log(file,'文件');
      this.files = file;
      const extension2 = file.name.split(".")[1] === "xlsx";
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!extension2) {
        this.$message.warning("上传模板只能是 xlsx格式!");
        return;
      }
      if (!isLt10M) {
        this.$message.warning("上传模板大小不能超过 10MB!");
        return;
      }
      this.fileName = file.name;
      setTimeout(() => {
        this.submitUpload();
      }, 500);
      return false; // 返回false不会自动上传
    },
    success() {
      this.$message.success("上传成功");
    },
    // 上传excel
    submitUpload() {
      //console.log('上传'+this.files.name)
      if (this.fileName == "") {
        this.$message.warning("请选择要上传的文件！");
        return false;
      }
      let fileFormData = new FormData();

      fileFormData.append("code", "t_pathology_info_excel");
      fileFormData.append("description", "供销单位批量上传");
      fileFormData.append("file", this.files, this.fileName);
      //filename是键，file是值，就是要传的文件，test是要传的文件名
      fileFormData.append("company_id", this.page.company_id);
      // 执行上传excel
      let id = "";
      axios
        .uploadFile("/pc/company-supply/importExcel", fileFormData)
        .then((resp) => {
          if (resp.code != 200) {
            this.$message.error("excel上传失败，请重新上传");
          }
          if (resp.data) {
            this.$message.warning(
              "该Excel中已经是与该公司具有供销单位关系的企业有：" +
                resp.data.length +
                "家"
            );
          } else {
          }
          this.init(this.page);
        })
        .catch((e) => {
          this.$message.error("excel上传失败，请重新上传");
        })
        .finally(() => {
          if (id) {
            // 触发生成订单实体数据
            this.generateBill(id);
          }
        });
    },
    deleteInfo(row) {
      console.log(row);
      api
        .post("/v1/wx/supply/pcRemove", {
          companySupplyId: row.company_supply_id,
          creditCode: row.credit_code,
          companyId: this.page.company_id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.init(this.page);
        })
        .catch((res) => {
          this.message({
            type: "error",
            message: "操作异常",
          });
        });
      //将供销单位的id传入后台进行删除
      // axios
      //   .post("/pc/company-supply/delete", {
      //     company_supply_id: row.company_supply_id,
      //   })
      //   .then((res) => {
      //     res = res.data;
      //     if (res) {
      //       this.$message({
      //         type: "success",
      //         message: "删除成功",
      //       });
      //     } else {
      //       this.message({
      //         type: "error",
      //         message: "删除失败",
      //       });
      //     }
      //     this.init(this.page);
      //   })
      //   .catch((res) => {
      //     this.message({
      //       type: "error",
      //       message: "操作异常",
      //     });
      //   });
    },
    handlePictureCardPreview(url) {
      if (url) {
        this.dialogImageUrl = url;
        this.showViewer = true;
      } else {
        this.$message.warning("没有上传证件！");
      }
    },
    init(params) {
      axios.get("/pc/company-supply/list", params).then((v) => {
        this.tableData = v.data.company_list;
        this.total = v.data.pager_count;
      });
    },
    downloadInfo(param) {
      axios
        .fileget("/pc/download/template", { fileName: "supply.xlsx" })
        .then((res) => {});
    },
    // 搜索
    onSubmit() {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.init(this.page);
    },
    // 分页
    currentChange(page) {
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10);
      this.init(this.page);
    },
    //批量导入
    batchAdd() {
      this.uploadVis = true;
    },
    //编辑
    goUpdate(row) {
      this.$router.push({
        name: "WarehouseListAdd",
        query: { warehouse_id: row.id, nature: row.nature },
      });
    },
    handleStateChange(val, row) {
      const { id } = row;
      let car_state = val + "";
      axios.put("", { car_id: id + "", car_state }).then((response) => {
        this.init(this.page);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.el-icon-plus {
  width: 267px;
  height: 178px;
  border: 1px dashed #ccc;
  display: block;
}
.avatar {
  width: 267px;
  height: 178px;
  display: block;
}
#allmap {
  width: 100%;
  height: 100%;
}
</style>
